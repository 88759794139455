















































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import { deleteMeetingRuleAlert } from "@/utils/notificationContent";
import MeetingRuleForm from "./MeetingRuleForm.vue";
import _ from "lodash";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    MeetingRuleForm,
  },
})
export default class MeetingRuleList extends Vue {
  @Action public getMeetingRules: any;
  @Action public getMeetingRuleCount: any;
  @Action public getMeetingRuleById: any;
  @Action public setMeetingRule: any;
  @Action public updateMeetingRule: any;
  @Action public getAppointmentTypes: any;
  @Getter public meetingRuleCount: any;
  @Getter public meetingRules: any;
  @Getter public meetingRule: any;
  @Getter public meetingRuleInitData: any;
  @Action public getLocationDropdown: any;
  @Action public deleteMeetingRule: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  ref: any = "appModalRef";
  meetingRuleFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: { eq: true },
    },
    fields: {
      created_by: false,
      updated_by: false,
      created_at: false,
      updated_at: false,
    },
    include: [
      {
        relation: "rulelocations",
        scope: {
          fields: ["id", "name"],
        },
      },
    ],
  };
  appointmentTypeFilter: any = {
    where: {
      status: { eq: true },
    },
    order: "id ASC",
  };
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";

  beforeMount() {
    this.getMeetingRuleList();
    this.getAppointmentTypes(this.appointmentTypeFilter);
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length > 0 ) {
      this.meetingRuleFilter.where.or = [
        {applies_to: { ilike: `%${this.searchText}%` }},
        {no_of_appointments: { ilike: `%${this.searchText}%` }},
      ];
      this.meetingRuleFilter.skip = 0;
      this.getMeetingRuleList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  getLocationText(ruleLocations: any): string {
    const groupedById = _.groupBy(ruleLocations, (value) => value.name);
    return Object.keys(groupedById).toString();
  }

  getMeetingRuleList() {
    this.getMeetingRules(this.meetingRuleFilter);
    this.getMeetingRuleCount(this.meetingRuleFilter.where);
  }

  addMeetingRule() {
    this.setMeetingRule({
      applies_to: null,
      no_of_appointments: null,
      duration: null,
      location_ids: [],
      status: true,
    });
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    if (isPageRefresh) {
      this.getMeetingRuleList();
    }
  }

  editMeetingRule(meetingRule: any): void {
    this.setMeetingRule(meetingRule);
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  deleteRule(id: number): void {
    alert.methods.conFirmAlert(
      deleteMeetingRuleAlert,
      () => {
        this.deleteMeetingRule({
          data: id,
          callback: this.getMeetingRuleList,
        });
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.meetingRuleFilter.skip = 0;
    this.getMeetingRuleList();
  }

  onPageChange(currentPage: any) {
    this.meetingRuleFilter.skip =
      (currentPage - 1) * this.meetingRuleFilter.limit;
    this.getMeetingRuleList();
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }
}
