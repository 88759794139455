








































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AppModal from "@/components/baseComponents/Modal/Index.vue";

@Component({
    components:{
        AppModal
    }
})
export default class AppointmentSettingsForm extends Vue {
    @Getter public currentUser: any;
    @Action public getLocations: any;
    @Getter public tenantLocations: any;
    autoScheudler = false;
    manualScheduler = false;
    days;
    time;
    duration;
    bScheduler;
    aScheduler;
    dApptNotification = true;
    locations:any = [];
    months: any = [
        {
            key: 3,
            value: 3
        },
        {
            key: 6,
            value: 6
        },
        {
            key: 12,
            value: 12
        }
    ];
    locationFilter: any = {
    fields: ['id','name'],
    where: { status : {eq:true} }, 
    order: ["name ASC"]
  };
  value = 0;  
    $refs!: {
    appModalRef: HTMLFormElement;
  };
 eventSource: EventSource | null = null;
 genearteBtn = false;
 disableRest = false;

  beforeDestroy() {
    this.closeSSE();
  }

  // Initialize the EventSource for Server-Sent Events
  initializeSSE(ids:number[]) {
    const { VUE_APP_API_PROTOCOL, VUE_APP_API_HOST } = process.env;
    this.genearteBtn = true;
    const queryString = JSON.stringify({where:{locationId:ids}});
    const url = `${VUE_APP_API_PROTOCOL}${VUE_APP_API_HOST}/execute-tasks?filter=${queryString}`; // SSE endpoint
    this.eventSource = new EventSource(url);

    // Listen to incoming messages
    this.eventSource.onmessage = (event) => {
      if (event.data === '[DONE]') {
       this.closeSSE();
      } else {
        const update = JSON.parse(event.data);
        const index = this.locations.findIndex((v:any)=>v.id==update.id);
        this.locations[index].task = update.result;        
        this.value = update.progress;
      }
    };

    // Handle errors
    this.eventSource.onerror = () => {
     this.closeSSE();
    };
  }

  // Close the EventSource connection
  private closeSSE() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
      this.genearteBtn = false;
      this.disableRest = false;
    }
  }


    beforeMount() {
        this.getLocations(this.locationFilter);       
    }

    onCancel() {
    }

    backToSettings(): void {
        this.$router.push({ name: "setting.list" }).catch();
    }

    viewLocationList(){
        if(this.tenantLocations.length){
            this.tenantLocations.map((v:any)=>{
                v.task='Not Yet Started'
            });
            this.locations = this.tenantLocations;            
        }
        this.value=0;
        const appModal = this.$refs.appModalRef;
        appModal.show();

    }

    removeLocation(id:number){
        this.locations = this.locations.filter((v:any)=>v.id != id); 
    }

    generate(){
        if(this.locations.length > 0){
            this.initializeSSE(this.locations.map((v:any)=>v.id));
            this.locations.map((v:any)=>{
                v.task='Initialize'
            });
            this.disableRest = true;
        }        
    }

    resetLocations(){
        this.locations = this.tenantLocations;
        this.value = 0;
    }
}

