
































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  components: {
  },
})
export default class SettingList extends Vue {
  @Getter public role!: any;
  @Getter public currentUser!: any;

}
