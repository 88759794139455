







































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LocationForm from "@/components/locations/LocationForm.vue";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import moment from "moment";
// import momenttz from "moment-timezone";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import constants from "@/constants/_message";
import alert from "@/mixins/alert";
import { 
  cancelMeetingAlert, 
  confirmLocationTimeSlotMeetingCancelAlert, 
  deleteLocationTimeSlotAlert, 
  revokeLocationAlert, 
  suspendLocationAlert
  } from "@/utils/notificationContent";
import GenerateMeetingForm from "@/components/generateMeetingForm/GenerateMeetingForm.vue";
import _ from "lodash";

@Component({
  components: { LocationForm, AppModal, GenerateMeetingForm },
})
export default class LocationUpdateForm extends Vue {
  @Action public initNewLocation: any;
  @Action public getLocationDropdown: any;
  @Action public updateLocation: any;
  @Action public getPolicies: any;
  @Action public getLocationById: any;
  @Action public getTenantPreferences: any;
  @Action public getAppointmentTypes: any;
  @Action public setLocationTimeslot: any;
  @Action public createLocationTimeslot: any;
  @Action public updateLocationTimeslot: any;
  @Getter public location: any;
  @Getter public users: any;
  @Getter public locations: any;
  @Getter public tenantPolicies: any;
  @Getter public currentUser: any;
  @Getter public tenantPreferences: any;
  @Getter public locationTimeslot: any;
  @Getter public locationTimeslotInitData: any;
  @Getter public appointmentTypes: any;
  @Getter public role!: any;
  @Action public deleteTimeSlot: any;
  @Action public suspendOrRevokeLocation: any;
  @Action public setLocation: any;
  selectedPolicyIds = [];
  locationId = 0;
  action = "add";
  activeTabName = "BASIC_INFO";
  isSubmitted = false;
  isRefreshList = false;
  isLoaded = false;
  $refs!: {
    timeslotForm: InstanceType<typeof ValidationObserver>;
    appModalRef: HTMLFormElement;
    generateMeetingModal: HTMLFormElement;
  };
  roundedCurrentDate = moment().toISOString();
  timezone = "";
  dateObject = {
    startTime: this.roundedCurrentDate,
    endTime: moment(this.roundedCurrentDate).add(30, "minutes").toISOString(),
  };
  locationPolicies: any = [];
  locationPreferences: any = [];
  selectedPreferenceIds: any = [];
  selectedAppointmentType: any = {};
  appointmentLabelDisabled = false;
  endDateDisabled = true;
  schedule: any = []
  weekdayString = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  weekdayOptions = [
    {
      key: 'Monday',
      value: 1
    },
    {
      key: 'Tuesday',
      value: 2
    },
    {
      key: 'Wednesday',
      value: 3
    },
    {
      key: 'Thursday',
      value: 4
    },
    {
      key: 'Friday',
      value: 5
    },
    {
      key: 'Saturday',
      value: 6
    },
    {
      key: 'Sunday',
      value: 7
    },
  ];
  selectedDaySchedule: any = [];
  selectedDay = 1;

  beforeMount() {
    this.locationId = Number(this.$route.params.id);
    // this.timezone = momenttz.tz.guess();
    this.getPolicies({ where: { status: { eq: true } }, order: ["policy_name ASC"] });
    this.getTenantPreferences({ where: { status: { eq: true } }, order: ["preference_name ASC"]});
    this.getAppointmentTypes({ where: { status: { eq: true } }, order: ["appointment_type ASC"] });
  }

  getLocationDetails() {
    setTimeout(() => {
      this.getLocationById(this.locationId);
    }, 1000);
  }

  @Watch("location")
  locationChange() {
    const activePolicies = this.location.policies.filter((policy: any) => { return policy.policy.status });
    this.selectedPolicyIds = activePolicies.map((policy: any) => policy.policyId);
    const activePreference = this.location.preferences.filter((preference: any) => { return preference.preference.status });
    this.selectedPreferenceIds = activePreference.map((preference: any) => preference.preferenceId);
    this.constructLocationPolicyData();
    this.schedule = this.location.schedule && this.location.schedule.length > 0 ?_.mapValues(_.groupBy(this.location.schedule, 'weekday'), clist => clist.map(car => _.omit(car, 'weekday'))) : [];
    if(this.currentUser.tenant && !this.currentUser.tenant.allow_weekend_meetings) {
      delete this.schedule[6];
      delete this.schedule[7];
    }
  }

  setDayForSchedule(value: any) {
    this.selectedDay = value;
    this.selectedDaySchedule = this.schedule[value] ? this.schedule[value] : [];
  }

  constructLocationPolicyData() {
    this.location?.policies?.forEach((policy: any) => {
      let temp = this.tenantPolicies?.find((tenantPolicy: any) => policy.policyId == tenantPolicy.id);
      if(temp) {
        temp.input_value = policy.input_value;
      }
    });
    this.location?.preferences?.forEach((preference: any) => {
      let temp = this.tenantPreferences?.find((tenantPreference: any) => preference.preferenceId == tenantPreference.id);
      if(temp) {
        temp.input_value = preference.input_value;
      }
    });
  }

  setAppointment(value: any) {
    this.locationTimeslot.appointmentTypeId = value;
    const temp = this.appointmentTypes.filter((appointmentType :any) =>  { return appointmentType.id === value });
    this.locationTimeslot.start_time = temp[0].start_time;
    this.locationTimeslot.end_time = temp[0].end_time;
    this.setLocationTimeslot(this.locationTimeslot);
  }

  getAppointment(id:any) {
    return this.appointmentTypes.find((appointment:any) => appointment.id === id).appointment_type;
  }

  openGenerateMeetingModal(value: any) {
    this.selectedAppointmentType  = value;
    this.endDateDisabled = true;
    const generateMeetingModal = this.$refs.generateMeetingModal as HTMLFormElement;
    generateMeetingModal.show();
  }

  changeTab(tab: string): void {
    this.activeTabName = tab;
  }

  onActionPerformed() {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.getLocationDetails();
    //this.$emit("actionPerformed", this.isRefreshList);
  }

  editTimeSlot(timeSlot: any) {
    this.action = "edit";
    this.appointmentLabelDisabled = true;
    this.getAppointmentTypes({where: {status: { eq: true}}});
    const startDate = moment(this.roundedCurrentDate).format('DD-MM-YYYY');
    this.setLocationTimeslot(timeSlot);
    let editStartDate = moment(startDate+' '+timeSlot.start_time).toISOString();
    let editEndDate = moment(startDate+' '+timeSlot.end_time).toISOString();
    this.dateObject = {
      startTime: editStartDate,
      endTime: editEndDate,
    };
    const appModal = this.$refs.appModalRef;
    appModal.show();
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.timeslotForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.timeslotForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.timeslotForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.isRefreshList = true;
        if (this.action === "add") {
          delete this.locationTimeslot.id;
          this.createLocationTimeslot({
            data: this.locationTimeslot,
            callBack: this.onActionPerformed});
        } else {
          // this.confirmUpdateAppointmentType();
          const { start_time, end_time, appointment_label, id} = this.locationTimeslot;
          this.updateLocationTimeslot({
            data: {start_time, end_time, appointment_label, id},
            callBack: this.onActionPerformed
            });
        }
        this.isSubmitted = false;
      }
    });
  }

  onCancel() {
    // this.setPolicy(this.tenantPolicyInitialData);
    // this.isRefreshList = false;
    //  this.$refs.tenantPolicyForm.reset();
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.onActionPerformed();
      },
      () => {}
    );
  }

  onPolicyPreferenceCancel() {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "locations.index" }).catch();
      },
      () => {}
    ); 
  }

  toggleAllPolicies(checked: any) {
    this.selectedPolicyIds = [];
    if(checked) {
      this.selectedPolicyIds = this.tenantPolicies.map((policy: any) => policy.id);
    }
  }

  toggleAllPreferences(checked: any) {
    this.selectedPreferenceIds = [];
    if(checked) {
      this.selectedPreferenceIds = this.tenantPreferences.map((preference: any) => preference.id);
    }
  }

  submitPolicy() {
    this.locationPolicies = [];
    this.selectedPolicyIds.forEach((policyId: any) => {
      const policy = this.tenantPolicies.find((tenantPolicy: any) => policyId === tenantPolicy.id);
      this.locationPolicies.push({policyId: policy.id, input_value: policy.input_value});
    });
    const data = {
      id: this.locationId,
      policies: this.locationPolicies,
    };
    this.updateLocation({ data, callBack: this.getLocationDetails });
  }

  submitPreferences() {
    this.locationPreferences = [];
    this.selectedPreferenceIds.forEach((preferenceId: any) => {
      const preference = this.tenantPreferences.find((tenantPreference: any) => preferenceId === tenantPreference.id);
      this.locationPreferences.push({preferenceId: preference.id, input_value: preference.input_value});
    });
    const data = {
      id: this.locationId,
      preferences: this.locationPreferences
    };
    this.updateLocation({ data, callBack: this.getLocationDetails });
  }

  backToLocations(): void {
    this.$router.push({ name: "locations.index" }).catch();
  }

  addTimeSlot() {
    this.action = "add";
    this.appointmentLabelDisabled = false;
    this.getAppointmentTypes({ where: { status: { eq: true } } });
    this.locationTimeslotInitData.locationId = this.locationId;
    this.locationTimeslotInitData.appointmentTypeId = 0;
    this.locationTimeslotInitData.appointment_label = '';
    this.locationTimeslotInitData.start_time = '';
    this.locationTimeslotInitData.end_time = '';
    this.setLocationTimeslot(this.locationTimeslotInitData);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  setStartTime(time: any): void {
    this.dateObject.startTime = time;
    this.dateObject.endTime = moment(time).add(30, "minutes").toISOString();
    let sTime = moment(this.dateObject.startTime).format("hh:mm A");
    let eTime = moment(this.dateObject.endTime).format("hh:mm A");
    this.locationTimeslot.start_time = sTime;
    this.locationTimeslot.end_time = eTime;
    this.setLocationTimeslot(this.locationTimeslot);
  }

  setEndTime(time: any): void {
    this.dateObject.endTime = time;
    this.locationTimeslot.end_time = this.dateObject.endTime;
    let eTime = moment(this.dateObject.endTime).format("hh:mm A");
    this.locationTimeslot.end_time = eTime;
    this.setLocationTimeslot(this.locationTimeslot);
  }

  deleteLocationTimeSlot(id: number): void {
    alert.methods.conFirmAlert(
      deleteLocationTimeSlotAlert,
      () => {
          this.confirmMeetingDelete(id);        
        },
      () => {}
    );
  }

  confirmMeetingDelete(id: number) {
    setTimeout(() => {
      alert.methods.conFirmAlert(
      confirmLocationTimeSlotMeetingCancelAlert,
        () => {
            this.deleteTimeSlot({ data: { id: id, is_delete_all_appointments: false }, callback: this.getLocationDetails});
          },
          () => {
            this.deleteTimeSlot({ data: { id: id, is_delete_all_appointments: true }, callback: this.getLocationDetails});
          }
        );
    }, 500);
  }


  getWeekdayString(selectedDays: any) {
    if(selectedDays && selectedDays.length) {
      let selectedDaysToDisplay : any = [];
      const weekdayString = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      selectedDays.forEach((selectedDay: any) => {
        selectedDaysToDisplay.push(weekdayString[selectedDay]);
      });
      return `${selectedDaysToDisplay.join(', ')}`;
    } else {
      return '';
    }
  }

  resetAppointmentType(value: any) {
    this.selectedAppointmentType = value;
  }

  suspendAppointmentsInLocation(location: any) {
  alert.methods.conFirmAlert(
      suspendLocationAlert,
      () => {
        this.suspendOrRevokeLocation({
          data: { id: location.id, suspend_appointments: true },
          callBack: () => {
            this.setSuspendOrRevokeLocationStatus(true);
          },
        });
      },
      () => {}
    );
  }

  revokeLocationAppointments(location: any) {
    alert.methods.conFirmAlert(
      revokeLocationAlert,
      () => {
        this.suspendOrRevokeLocation({
          data: { id: location.id, suspend_appointments: false },
          callBack: () => {
            this.setSuspendOrRevokeLocationStatus(false);
          },
        });
      },
      () => {}
    );
  }

  setSuspendOrRevokeLocationStatus(value: boolean) {
    let location = this.location;
    location.suspend_appointments = value;
    this.setLocation(location);
  }

  getLastAction(slot:any):string {
    const weekOfMonthName = ['first', 'second', 'third', 'forth', 'last'];
    if(!slot.selected_days?.length){
      return '';
    } else if(!slot.separation_count || !slot.recurring_type){
      slot.recurring_type = 'daily'
    }
    slot.days = this.getWeekdayString(slot.selected_days);
    let last = 'Last action: ';
    switch(slot.recurring_type){      
      case 'daily':
        last += 'Weekly on ' + slot.days;
        break;

      case 'weekly':
        last += `Every ${slot.separation_count} weeks on ${slot.days}`;
        break; 

      case 'monthly':
        if(slot.separation_count === 1) {
          last += `Monthly on the ${weekOfMonthName[+slot.week_of_month-1]} ${slot.days}`;
        } else {
          last += `Every ${slot.separation_count} months on the ${weekOfMonthName[+slot.week_of_month-1]} ${slot.days}`;
        }
        break;

      case 'monthlyOnSpecificDay':
      if(slot.separation_count === 1) {
          last += `Monthly on the day ${slot.day_of_month}`;
        } else {
          last += `Every ${slot.separation_count} months on day ${slot.day_of_month}`;
        }
        break;

      default:
        last = '';

    }
    return last;
  }
}
